import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Webinair from "../components/webinair";

// import { isSafari } from 'react-device-detect';

import "../pages/webinars-old.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

export default ({ data }) => {
    let title = '';
    let titleText = '';
    let metaDescriptionValue = '';

    let webinars = [];

    if (data.gcms && data.gcms.titles && data.gcms.metaDescriptions.length > 0) {
        for (const metaDescription of data.gcms.metaDescriptions) {
            if (metaDescription.indicator === 'webinars-meta-description') {
                metaDescriptionValue = metaDescription.value;
            }
        }
        for (const titleData of data.gcms.titles) {
            if (titleData.indicator === 'webinar-title') {
                title = titleData.value;
            }
            if (titleData.indicator === 'webinar-title-text') {
                titleText = titleData;
            }
        }
    }

    // if (data.gcms && data.gcms.webinars) {
    //     webinars = data.gcms.webinars;
    // }

    // if (data.allDemio && data.allDemio.edges) {
    //     webinars = data.allDemio.edges;
    // }

    return (
        <Layout>
            <SEO title="Webinars" description={metaDescriptionValue} urlParams="webinars" />
            <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
            <div className="webinars-container">
                <div className="inner-container">
                    <h1>{title}</h1>
                    <p className="inline">{titleText.value} </p>
                    {(titleText.richValue && titleText.richValue.html) &&
                        <div className="html-text inline" dangerouslySetInnerHTML={{ __html: nl2br(titleText.richValue.html) }} />
                    }

                    {/* GraphCMS webinair example
                    {webinars.map((webinar) => {
                        return <Webinair key={webinar.title} data={webinar} safariCheck={isSafari} type="graphCMS" />
                    })} */}

                    {/* Demio Gatsby Get implementation */}
                    {webinars.map((webinar) => {
                        return <Webinair key={webinar.node.name} data={webinar.node} type="demio" />
                    })}
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
query {
    gcms {
        metaDescriptions {
            indicator
            value
        }
        titles {
            indicator
            value
            richValue { html }
        }
        webinars {
            id
            title
            extraTitleText
            shortDescription
            description { html }
            descriptionImageUrl
            enrollRedirectUrl
        }
    }
    # allDemio(sort: {fields: timestamp}, filter: {status: {ne: "finished"}}) {
    #     edges {
    #         node {
    #             name
    #             registration_url
    #             description
    #             timestamp
    #             zone
    #         }
    #     }
    # }
}
`