import React from "react";
import ModalImage from "react-modal-image";

import { isMobile, isTablet } from 'react-device-detect';

// import InfoIcon from '@material-ui/icons/Info';

import "./webinair.scss";

class Webinair extends React.Component {
    date;
    days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    monthNames = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];

    constructor(props) {
        super(props)
        this.state = { readMore: false };
        this.date = new Date(this.props.data.timestamp * 1000);
    }

    componentDidMount() { }

    componentDidUpdate() { }

    componentWillUnmount() { }

    nl2br(str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
    }

    render() {
        return (
            <>
                {(this.props.data != null && this.props.type !== "demio") &&
                    <div className="webinar-container" key={this.props.data.title}>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="extra-title-text">{this.props.data.extraTitleText}</p>
                                <h2 className="title">{this.props.data.title}</h2>
                                <p>{this.props.data.shortDescription}</p>
                            </div>
                            <div className="col-md-6">
                                {(isMobile === false || (isMobile === true && isTablet === true)) && <span>
                                    <a className="orange-button" rel="noreferrer" href={this.props.data.enrollRedirectUrl} target="_blank" >Meld mij nu aan</a>
                                    <p className="orange-button-text">Live demo</p>
                                </span>}
                            </div>
                        </div>
                        <div className={"row read-more-text " + (this.state.readMore === true && "show-read-more-text")}>
                            <div className="col-md-7">
                                {(this.props.data.description && this.props.data.description.html) &&
                                    <div className="html-text inline" dangerouslySetInnerHTML={{ __html: this.nl2br(this.props.data.description.html) }} />
                                }
                            </div>
                            <div className="col-md-5">
                                <ModalImage
                                    small={this.props.safariCheck === true ? this.props.data.descriptionImageUrl.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.data.descriptionImageUrl}
                                    large={this.props.safariCheck === true ? this.props.data.descriptionImageUrl.replace('.webp', '.png').replace('original/mdw', 'original/lg') : this.props.data.descriptionImageUrl}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <button className="more-info-button" onClick={() => this.setState({ readMore: !this.state.readMore })}>{this.state.readMore === false ? 'lees meer' : 'lees minder'}</button>
                        {(isMobile === true && isTablet === false) && <span>
                            <a className="orange-button-mobile" rel="noreferrer" href={this.props.data.enrollRedirectUrl} target="_blank" >Meld mij nu aan</a>
                            <p className="orange-button-mobile-text">Live demo</p>
                        </span>}
                    </div>
                }
                {(this.props.data != null && this.props.type === "demio") &&
                    <div className="webinar-container" key={this.props.data.id}>
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="title">{this.props.data.name}</h2>
                                <span className="extra-title-text">{this.days[this.date.getDay()] + ', ' + this.date.getDate() + ' ' + this.monthNames[this.date.getMonth()] + ' ' + this.date.getFullYear() + ' - ' + this.date.getHours() + ':' + (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes()}
                                    {/* <span className="info-icon">
                                        <InfoIcon />
                                        <div className="extra-info-popup">
                                            <b><p>Geen goed moment?</p></b>
                                            <p>Verderop deze pagina kun je eerdere demo's opvragen. Dan kun je ze terugkijken wanneer het jou het beste uitkomt.</p>
                                        </div>
                                    </span> */}
                                </span>
                                <div className="html-text inline" dangerouslySetInnerHTML={{ __html: this.nl2br(this.props.data.description) }} />
                            </div>
                            <div className="col-md-4">
                                {(isMobile === false || (isMobile === true && isTablet === true)) && <span>
                                    <a className="orange-button" rel="noreferrer" href={this.props.data.registration_url} target="_blank" >Meld mij nu aan</a>
                                    <p className="orange-button-text">Live demo</p>
                                </span>}
                            </div>
                        </div>
                        <a className="more-info-button" rel="noreferrer" href={this.props.data.registration_url} target="_blank">Lees meer</a>
                        {(isMobile === true && isTablet === false) && <span>
                            <a className="orange-button-mobile" rel="noreferrer" href={this.props.data.registration_url} target="_blank" >Meld mij nu aan</a>
                            <p className="orange-button-mobile-text">Live demo</p>
                        </span>}
                    </div>
                }
            </>
        );
    }
}

export default Webinair;
